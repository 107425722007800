<template>
  <div class="home">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        Справочник ({{ filters.total }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center w-full justify-end">
        <el-select
          @change="handleFiltersChange"
          filterable
          clearable
          v-model="filters.category_id"
          size="medium"
          class="mr-2"
          placeholder="Выберите категорию"
        >
          <el-option
            v-for="category in categories"
            :key="category.id"
            :label="category.name"
            :value="category.id"
          ></el-option>
        </el-select>

        <el-input
          v-model="filters.query"
          @keyup.native.enter="handleFiltersChange"
          @clear="handleFiltersChange"
          clearable
          size="medium"
          placeholder="Поиск"
          class="mr-2"
          style="width: 220px"
        ></el-input>

        <el-button
          size="medium"
          plain
          type="primary"
          icon="el-icon-plus"
          :disabled="loading"
          @click="editProduct({ id: 'new' })"
          >Добавить</el-button
        >
      </div>
    </h1>
    <el-table :data="products" border size="small" @row-click="editProduct">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        label="N"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column prop="brand" label="Бренд"> </el-table-column>
      <el-table-column prop="category_name" label="Категория">
      </el-table-column>
      <el-table-column prop="average_price" label="Средняя цена" align="right">
      </el-table-column>
      <el-table-column prop="guarantee_period" label="Гарантия" align="right">
      </el-table-column>
    </el-table>
    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getProducts } from "@/api/product";
import { getCategories } from "@/api/category";

export default {
  name: "Home",
  components: {},
  data: () => ({
    products: [],
    categories: [],
    loading: false,

    filters: {
      category_id: null,
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 10,
      query: null,
    },
  }),
  computed: {
    page() {
      return this.$route.query.page;
    },
    query() {
      return this.$route.query.query;
    },
    category_id() {
      return this.$route.query.category_id;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = parseInt(this.page, 10);
    }
    if (this.category_id) {
      this.filters.category_id = parseInt(this.category_id, 10);
    }
    if (this.query) {
      this.filters.query = this.query;
    }
    await this.getProducts();
    this.categories = await getCategories();
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const result = await getProducts(this.filters);
      const { products, pages_count, total } = result;
      this.products = products;
      this.filters.pages_count = pages_count;
      this.filters.total = total;
      this.loading = false;
    },
    editProduct(product) {
      this.$router.push({ name: "Product", params: { id: product.id } });
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          page: this.filters.page,
          query: this.filters.query,
          category_id: this.filters.category_id,
        },
      });
    },
    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getProducts();
    },
    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
  },
};
</script>

<style lang="scss">
table {
  th {
    padding: 10px;
  }
  td {
    padding: 10px;
  }
}
</style>
